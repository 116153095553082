import PropTypes from 'prop-types';

const Default = ({ scoreboard: { scrA, scrB } }) => (
  <div>
    <p>{scrA}</p>
    <p>{scrB}</p>
  </div>
);

Default.propTypes = {
  scoreboard: PropTypes.shape({
    scrA: PropTypes.number.isRequired,
    scrB: PropTypes.number.isRequired,
  }).isRequired,
};

export default Default;
