import PropTypes from 'prop-types';
import { format } from 'bv-helpers/price';

const CashoutTitle = ({ odds, title }) => (
  <div className="co-card__header-multi">
    <div className="co-card__header">
      <div className="co-card__title">{title}</div>
      <div className="co-card__odds">
        <span className="co-card__price">{format(odds)}</span>
      </div>
    </div>
  </div>
);

CashoutTitle.propTypes = {
  odds: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CashoutTitle;
