import classnames from 'classnames';
import PropTypes from 'prop-types';
import Legs from 'Cashout/components/legs_view';
import Summary from 'Cashout/components/summary/summary';
import { BetSource } from 'SharedComponents/my_bets/constants';
import { betResult as getResult } from 'Cashout/helpers/bets';
import CashoutCredit from './cashout_credit';
import AddUnsettledLegsToBetslip from './add_unsettled_legs_to_betslip';
import BetReturnsInfo from './bet_returns_info';
import BogExtraMoney from './bog_extra_money';
import CashoutTitle from './cashout_title';
import PartialCashouts from './partial_cashouts';
import ResultRibbon from './result_ribbon';

const BetView = ({
  betType,
  bogExtraMoney,
  cashedOut,
  cashingOut,
  cashOutCredit,
  cashoutable,
  confirmingCashout,
  formattedTotalPrice,
  id,
  isMultiple,
  isSingle,
  isSettledBet,
  labels,
  legs,
  normalReturns,
  partialCashoutCalc,
  partialCashOuts,
  result,
  returns,
  promoCash,
  taxDeductPct,
  totalCost,
  cashoutValue,
  partialCashoutValue,
  rejectedCashout,
  pendingPayout,
}) => {
  const betResult = isSettledBet && getResult({ isSingle, legs, result });
  const { betSource } = labels;

  return (
    <li className={classnames('bvs-card co-card', {
      'is-cashout-accepted': cashedOut,
      'is-full': cashoutValue === partialCashoutValue,
      'is-cashout-rejected': rejectedCashout,
      'is-partial': !confirmingCashout && !cashingOut && (partialCashoutValue !== cashoutValue),
      'is-confirm': confirmingCashout,
      [`is-settled is-${betResult}`]: betResult && !pendingPayout,
      [`is-settled is-${betResult}-pending`]: betResult && pendingPayout,
      'is-bog': bogExtraMoney > 0,
      'is-cashed-out-partial': partialCashOuts.length > 0 && isSettledBet,
    })}
    >
      {betResult && <ResultRibbon result={betResult} pendingPayout={pendingPayout} />}
      <div
        id={`cashout_${id}`}
        className={classnames('co-card__item', isMultiple ? 'co-card__item--multibet' : 'co-card__item--single')}
      >
        {isMultiple && <CashoutTitle title={betType} odds={formattedTotalPrice} />}

        <Legs legs={legs} isMultiple={isMultiple} betSource={betSource} />
        <BetReturnsInfo
          {...partialCashoutCalc || {
            totalCost,
            returns,
            normalReturns,
          }}
          formattedTotalPrice={formattedTotalPrice}
          isSettledBet={isSettledBet}
          hasPartialCashoutCalc={!!partialCashoutCalc}
          promoCash={promoCash}
          taxDeductPct={taxDeductPct}
        />
        {cashoutable && (
          <div className="co-footer">
            <Summary betId={id} />
          </div>
        )}

        {cashOutCredit > 0 && <CashoutCredit amount={cashOutCredit} />}
        {bogExtraMoney > 0 && <BogExtraMoney amount={bogExtraMoney} />}

        <PartialCashouts partialCashOuts={partialCashOuts} />
      </div>

      {betSource !== BetSource.LUCKY_DIP && <AddUnsettledLegsToBetslip legs={legs} />}
    </li>
  );
};

BetView.propTypes = {
  betType: PropTypes.string.isRequired,
  bogExtraMoney: PropTypes.number,
  cashedOut: PropTypes.bool.isRequired,
  cashingOut: PropTypes.bool.isRequired,
  cashoutable: PropTypes.bool,
  cashOutCredit: PropTypes.number,
  cashoutValue: PropTypes.number.isRequired,
  partialCashoutValue: PropTypes.number.isRequired,
  confirmingCashout: PropTypes.bool.isRequired,
  formattedTotalPrice: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  isSingle: PropTypes.bool.isRequired,
  isSettledBet: PropTypes.bool,
  labels: PropTypes.arrayOf(Object).isRequired,
  legs: PropTypes.arrayOf(Object).isRequired,
  normalReturns: PropTypes.number.isRequired,
  partialCashOuts: PropTypes.arrayOf(Object).isRequired,
  partialCashoutCalc: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    normalReturns: PropTypes.number,
    returns: PropTypes.number,
    totalCost: PropTypes.number,
  }),
  rejectedCashout: PropTypes.bool.isRequired,
  result: PropTypes.string,
  returns: PropTypes.number.isRequired,
  promoCash: PropTypes.bool.isRequired,
  taxDeductPct: PropTypes.string,
  totalCost: PropTypes.number.isRequired,
  pendingPayout: PropTypes.bool.isRequired,
};

BetView.defaultProps = {
  bogExtraMoney: 0,
  cashoutable: false,
  cashOutCredit: 0,
  isSettledBet: false,
  partialCashoutCalc: null,
  taxDeductPct: null,
  result: '',
};

export default BetView;
