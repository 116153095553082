import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { addMultiple } from 'bv-services/betslip-adapter';
import { SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import { showBetslip } from 'right-sidebar';
import { isOpenLeg } from 'Cashout/helpers/legs';

const AddUnsettledLegsToBetslip = ({ legs }) => {
  const [isAddingToBetslip, setIsAddingToBetslip] = useState(false);

  const outcomesIds = useMemo(() => legs
    .filter(({ values }) => isOpenLeg(values))
    .map((({ outcome }) => outcome.id)), [legs]);

  const handleOnClick = async () => {
    setIsAddingToBetslip(true);
    addMultiple(outcomesIds)
      .then(showBetslip)
      .finally(() => setIsAddingToBetslip(false));
  };

  return outcomesIds.length > 0 && (
    <div className="co-card__add-to-betslip">
      {
        isAddingToBetslip ? <SmallSpinner /> : (
          <div className="bvs-link" onClick={handleOnClick}>
            {t('javascript.cashout.add_to_betslip')}
          </div>
        )
      }
    </div>
  );
};

AddUnsettledLegsToBetslip.propTypes = {
  legs: PropTypes.arrayOf(Object).isRequired,
};

export default AddUnsettledLegsToBetslip;
