import PropTypes from 'prop-types';

const Sets = ({ scoreboard: { headers, scoresA, scoresB } }) => (
  <>
    {headers.map((h, i) => (
      <div key={h}>
        <p>{h}</p>
        <p>{scoresA[i]}</p>
        <p>{scoresB[i]}</p>
      </div>
    ))}
  </>
);

Sets.propTypes = {
  scoreboard: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    scoresA: PropTypes.arrayOf(PropTypes.number).isRequired,
    scoresB: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default Sets;
