import PropTypes from 'prop-types';
import classnames from 'classnames';
import { format } from 'bv-helpers/price';

const CashoutPrice = ({
  price,
  enhancedOdds,
}) => (
  <div className="co-card__odds">
    <div className={classnames('co-card__odds-wrapper', {
      'co-card__odds-wrapper--enhanced': enhancedOdds,
    })}
    >
      {enhancedOdds && (
        <span className="co-card__price co-card__price--enhanced">{format(enhancedOdds)}</span>
      )}
      <span className={classnames('co-card__price', {
        'co-card__price-previous': enhancedOdds,
      })}
      >
        {format(price)}
      </span>
    </div>
  </div>
);

CashoutPrice.propTypes = {
  enhancedOdds: PropTypes.string,
  price: PropTypes.string.isRequired,
};

CashoutPrice.defaultProps = {
  enhancedOdds: null,
};

export default CashoutPrice;
