import { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import { t } from 'bv-i18n';

import { hide } from 'right-sidebar';
import { EventIcons } from 'sportsbook-components';
import Scoreboard from 'Cashout/components/scoreboard/scoreboard';
import MyBetsContext from 'Cashout/context';
import {
  bestOddsGuaranteed,
  canNavigateToEvent,
  legResult,
  eventUrl,
  formatFullDate,
  isOpenLeg,
  isNone,
  isWon,
  isSuspended,
  hasResult,
} from 'Cashout/helpers/legs';
import { useSoFarScores, SoFarScores } from './so_far_scores';
import BogIcon from './bog_icon';
import HorseRacingModifiers from './horse_racing_modifiers';
import CashoutPrice from './cashout_price';
import ClockInfo from './clock_info';
import LegIcon from './leg_icon';
import EventComment from './event_comment_redux';
import BetBuilderLegs from './bet_builder_legs';
import EventDescription from './event_description';

const LegView = ({
  betSource,
  isMultiple,
  scoreboard,
  leg,
  leg: {
    enhancedOdds,
    event,
    inPlay,
    inRunning,
    market,
    meetingDescription,
    outcome,
    price,
    sportId,
    isGreyhound,
    isHorseRacing,
    ewPlaceTerms,
    marketDescription,
    values,
  },
}) => {
  const { betsStatus, eventId, isEventLevel } = useContext(MyBetsContext);
  const bogPrice = bestOddsGuaranteed(values);
  const isOpen = isOpenLeg(values);
  const showScoreboard = inPlay && scoreboard && !isHorseRacing;
  const hasScoreboardHeaders = showScoreboard && scoreboard.scoresA && scoreboard.scoresB;
  const outcomeLegs = outcome.legs || [];
  const isBetBuilderBet = !!outcomeLegs.length;
  const soFarScoresEnabled = inPlay || (hasResult(values) && !isNone(values));

  useSoFarScores({ url: outcome.scoreAddress, shouldRegister: soFarScoresEnabled });

  const innerContentLink = isOpen && (
    <div className={classnames('co-event', {
      'co-event--in_play': inPlay,
      'co-event--pre_event': !inPlay,
    })}
    >
      {betsStatus === 'open' && <EventComment event={event} sportId={sportId} />}
      <ClockInfo leg={leg} scoreboard={scoreboard} hasScoreboardHeaders={hasScoreboardHeaders} />
      <EventDescription leg={leg} hasScoreboardHeaders={hasScoreboardHeaders} />
      {showScoreboard && <Scoreboard scoreboard={scoreboard} />}
    </div>
  );

  return (
    <li className={classnames('co-leg', { 'co-leg--won': isWon(values) })}>
      <div className={classnames('co-leg__item', {
        'co-inplay-status': inPlay,
        'co-pre-status': !inPlay,
        'co-bb-leg': isBetBuilderBet,
      })}
      >
        <div className="co-card__header">
          <LegIcon
            isNoneResult={isNone(values)}
            sportId={sportId}
            state={legResult(values)}
            betBoostType={market.boosted || market.betBoostType}
            betBuilderType={isBetBuilderBet}
            enhancedOdds={enhancedOdds}
            silk={isHorseRacing && outcome.colour}
            isMultiple={isMultiple}
            isSuspended={isSuspended(leg)}
            betSource={betSource}
          />
          <div className="co-card__title">
            {isBetBuilderBet ? marketDescription : outcome.description}
            {!isEventLevel && <EventIcons badges={event.badges} />}
          </div>
          {isHorseRacing && bogPrice && <BogIcon />}
          <CashoutPrice
            price={price}
            enhancedOdds={(isHorseRacing && bogPrice) || enhancedOdds}
          />
        </div>

        {isBetBuilderBet && (
          <BetBuilderLegs
            soFarScoresEnabled={soFarScoresEnabled}
            legs={outcome.legs}
          />
        )}

        <div className="co-card__alt-title">
          <p>{marketDescription}</p>
          {ewPlaceTerms && (<p>{ewPlaceTerms}</p>)}
          {!isOpen && (
            <>
              <p>{formatFullDate(event.startTime)}</p>
              <p>{isHorseRacing || isGreyhound ? meetingDescription : event.description}</p>
            </>
          )}
          {inRunning && <p>{t('in_play_bets_indicator.placed_in_play')}</p>}
          {!isBetBuilderBet && <SoFarScores id={outcome.scoreAddress} />}
        </div>

        {!(isEventLevel && eventId === event.id) && canNavigateToEvent(leg) ? (
          <Link to={eventUrl(leg)} onClick={hide}>
            {innerContentLink}
          </Link>
        ) : innerContentLink}

        <HorseRacingModifiers modifiers={values?.modifiers} />
      </div>
    </li>
  );
};

LegView.propTypes = {
  leg: PropTypes.shape({
    title: PropTypes.string,
    event: PropTypes.shape({
      badges: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
      id: PropTypes.number,
      opponentADesc: PropTypes.string,
      opponentBDesc: PropTypes.string,
      pathId: PropTypes.number,
      startTime: PropTypes.string,
    }),
    market: PropTypes.shape({
      boosted: PropTypes.bool,
      betBoostType: PropTypes.bool,
      betBuilderType: PropTypes.bool,
    }),
    meetingDescription: PropTypes.string,
    price: PropTypes.string,
    sportId: PropTypes.number,
    meetingId: PropTypes.number,
    scoreboard: PropTypes.instanceOf(Object),
    values: PropTypes.shape({
      modifiers: PropTypes.instanceOf(Object),
    }),
    inPlay: PropTypes.bool,
    inRunning: PropTypes.bool,
    enhancedOdds: PropTypes.string,
    outcome: PropTypes.shape({
      id: PropTypes.string,
      colour: PropTypes.string,
      description: PropTypes.string,
      legs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
      scoreAddress: PropTypes.string,
    }),
    isGreyhound: PropTypes.bool,
    isHorseRacing: PropTypes.bool,
    ewPlaceTerms: PropTypes.string,
    marketDescription: PropTypes.string,
  }).isRequired,
  betSource: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  scoreboard: PropTypes.instanceOf(Object).isRequired,
};

export default LegView;
