import PropTypes from 'prop-types';
import { Icon, Silk } from 'bv-components';
import { dasherize } from 'underscore.string';
import { BetSource } from 'SharedComponents/my_bets/constants';

const LegIcon = ({
  betBoostType,
  betBuilderType,
  betSource,
  enhancedOdds,
  isMultiple,
  isNoneResult,
  isSuspended,
  silk,
  sportId,
  state,
}) => {
  let className;
  if (isSuspended) {
    className = 'is-suspended';
  } else if (!isNoneResult && isMultiple) {
    className = `is-${state}`;
  } else if (betBoostType || enhancedOdds) {
    className = 'bvs-event-icon is-bet-boost';
  } else if (betBuilderType) {
    className = 'bvs-event-icon is-piu';
  } else if (betSource && Object.values(BetSource).includes(betSource)) {
    className = `bvs-event-icon is${dasherize(betSource)}`;
  } else {
    if (silk) {
      return (
        <div className="co-card__icon-silks">
          <Silk silk={silk} />
        </div>
      );
    }
    className = `is-sport-${sportId}`;
  }

  return (
    <Icon type="cashout-card-icon" className={`co-card__icon ${className}`} />
  );
};

LegIcon.propTypes = {
  betBoostType: PropTypes.bool.isRequired,
  betBuilderType: PropTypes.bool.isRequired,
  betSource: PropTypes.string,
  enhancedOdds: PropTypes.string,
  isNoneResult: PropTypes.bool.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  isSuspended: PropTypes.bool.isRequired,
  silk: PropTypes.string,
  sportId: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

LegIcon.defaultProps = {
  betSource: null,
  enhancedOdds: null,
  silk: null,
};

export default LegIcon;
